import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getChat(url = "", tgUserData) {
    return callApi
      .request({
        headers: {
          'TG-USER-DATA': tgUserData
        },
        method: "GET",
        url: URL.TELEGRAM_GK_SAMOLET_CHAT + url,
      })
      .then((res) => res.data);
  },

  sendMessage(data, tgUserData) {
    return callApi
      .request({
        headers: {
          'TG-USER-DATA': tgUserData
        },
        method: "POST",
        url: URL.TELEGRAM_GK_SAMOLET_SEND_MESSAGE,
        data,
      })
      .then((res) => res.data);
  },
};
