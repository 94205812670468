<template>
  <div class="chat">
    <!--    <div class="chat__list-item">Приветствую: {{ this.tg.initDataUnsafe?.user?.first_name ? this.tg.initDataUnsafe?.user?.first_name : this.tg.initDataUnsafe?.user?.username }}</div>
    <div v-for="message in messages" :key="message.id" class="chat__item">
      <div class="chat__list-item">
        message.text
      </div>
    </div>-->

    <div class="center">
      <div class="chat">
        <div id="chat" class="messages">
          <div class="messages-list">
            <div
              v-for="message in messages"
              :key="message.id"
              :class="message.fromTelegramUserId ? 'message user' : 'message'"
            >
              {{ message.message }}
            </div>
          </div>
          <div class="input">
            <input id="user-input-text" type="text" placeholder="Написать сообщение" />
            <i class="fas fa fa-send" @click="sendMessage"
              ><svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 32 32" width="32">
                <path
                  d="m29.919 6.163-4.225 19.925c-.319 1.406-1.15 1.756-2.331 1.094l-6.438-4.744-3.106 2.988c-.344.344-.631.631-1.294.631l.463-6.556 11.931-10.781c.519-.462-.113-.719-.806-.256l-14.75 9.288-6.35-1.988c-1.381-.431-1.406-1.381.288-2.044l24.837-9.569c1.15-.431 2.156.256 1.781 2.013z"
                /></svg
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ROUTE from "~/constants/routes";
import telegramApi from "~/api/telegram";
import feedApi from "@/api/feed";

export default {
  name: "GKSamolet",
  metaInfo: {
    title: "Пятницкое",
  },
  data() {
    return {
      page: 1,
      messages: [],
      user: {},
      tgUserData: {},
      tg: {},
      chat: {},
    };
  },
  components: {},
  mounted() {
    this.scrollToBottom();

    this.tgReady();
    this.loadChat();

    setInterval(() => {
      this.loadChat();
    }, 3000);
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    tgReady() {
      this.tg = window.Telegram.WebApp;
      this.tgUserData = this.tg.initData;

      this.tg.ready();
    },
    tgClose() {
      this.tg.close();
    },
    scrollToBottom() {
      let where = document.getElementById("user-input-text");
      //window.scrollBy(0, where.getBoundingClientRect().top - document.documentElement.clientHeight + where.offsetHeight);
      where.scrollIntoView({ block: "center", behavior: "smooth" });
    },
    loadChat() {
      this.chat = document.getElementById("chat");
      let url = `?page=${this.page}`;

      telegramApi.getChat(url, this.tgUserData).then((res) => {
        this.messages = res.data.data;

        this.scrollToBottom();
      });
    },
    getInputText() {
      return document.getElementById("user-input-text").value;
    },
    setInputText(text) {
      document.getElementById("user-input-text").value = text;
    },
    sendMessage() {
      let text = this.getInputText();

      telegramApi
        .sendMessage(
          {
            message: text,
          },
          this.tgUserData,
        )
        .then((res) => {
          let added = res.data;

          this.messages.unshift(added);

          this.setInputText("");

          this.scrollToBottom();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}

.chat {
  &__title {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &__item {
    padding: 15px 15px 5px;
    margin-bottom: 10px;
    background: rgb(238, 238, 238);
    border-radius: 10px;
  }

  &__top {
    display: flex;
    padding-bottom: 15px;

    div {
      margin-right: 30px;
      font-size: 18px;
    }
  }

  &__list-item {
    font-size: 18px;
    padding-bottom: 10px;
  }
}

$text-1: #333;
$text-2: #666;
$text-3: #999;
$line: #ccc;
$time-bg: #eee;
$background: #f7f7f7;

.center {
  /*position: absolute;*/
}

.pic {
  width: 4rem;
  height: 4rem;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.contact {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 5rem;
  height: 4.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .pic {
    position: absolute;
    left: 0;
  }

  .name {
    font-weight: 500;
    margin-bottom: 0.125rem;
  }

  .message,
  .seen {
    font-size: 0.9rem;
    color: $text-3;
  }

  .badge {
    box-sizing: border-box;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    font-size: 0.9rem;
    padding-top: 0.125rem;
    border-radius: 1rem;

    top: 0;
    left: 2.5rem;
    background: $text-1;
    color: white;
  }
}

.contacts {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-6rem, -50%);

  width: 24rem;
  height: 32rem;
  padding: 1rem 2rem 1rem 1rem;
  box-sizing: border-box;
  border-radius: 1rem 0 0 1rem;

  cursor: pointer;

  background: white;
  box-shadow: 0 0 8rem 0 rgba(black, 0.1), 2rem 2rem 4rem -3rem rgba(black, 0.5);

  transition: transform 500ms;

  h2 {
    margin: 0.5rem 0 1.5rem 5rem;
  }

  .fa-bars {
    position: absolute;
    left: 2.25rem;

    color: $text-3;
    transition: color 200ms;

    &:hover {
      color: $text-2;
    }
  }

  .contact:last-child {
    margin: 0;
  }

  &:hover {
    transform: translate(-23rem, -50%);
  }
}

.messages-list {
  display: flex;
  flex-direction: column-reverse;
}

.chat {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 1rem;

  background: white;
  box-shadow: 0 0 8rem 0 rgba(black, 0.1), 0 rem 2rem 4rem -3rem rgba(black, 0.5);

  .contact.bar {
    flex-basis: 3.5rem;
    flex-shrink: 0;
    margin: 1rem;
    box-sizing: border-box;
  }

  .messages {
    padding: 1rem;
    background: $background;
    flex-shrink: 2;
    overflow-y: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    box-shadow: inset 0 2rem 2rem -2rem rgba(black, 0.05),
      inset 0 -2rem 2rem -2rem rgba(black, 0.05);

    .time {
      font-size: 0.8rem;
      background: $time-bg;
      padding: 0.25rem 1rem;
      border-radius: 2rem;
      color: $text-3;
      width: fit-content;
      margin: 0 auto;
    }

    .message {
      box-sizing: border-box;
      padding: 0.5rem 1rem;
      margin: 1rem;
      background: #fff;
      border-radius: 1.125rem 1.125rem 1.125rem 0;
      min-height: 2.25rem;
      width: fit-content;
      max-width: 66%;

      box-shadow: 0 0 2rem rgba(black, 0.075), 0 rem 1rem 1rem -1rem rgba(black, 0.1);

      &.user {
        margin: 1rem 1rem 1rem auto;
        border-radius: 1.125rem 1.125rem 0 1.125rem;
        background: $text-1;
        color: white;
      }

      .typing {
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0;
        box-sizing: border-box;
        background: #ccc;
        border-radius: 50%;

        &.typing-1 {
          animation: typing 3s infinite;
        }
        &.typing-2 {
          animation: typing 3s 250ms infinite;
        }
        &.typing-3 {
          animation: typing 3s 500ms infinite;
        }
      }
    }
  }

  .input {
    box-sizing: border-box;
    flex-basis: 4rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 1.5rem;

    i {
      font-size: 1.5rem;
      margin-right: 1rem;
      color: $text-2;
      cursor: pointer;
      transition: color 200ms;

      &:hover {
        color: $text-1;
      }
    }

    input {
      border: none;
      background-image: none;
      background-color: white;
      padding: 0.5rem 1rem;
      margin-right: 1rem;
      border-radius: 1.125rem;
      flex-grow: 2;
      box-shadow: 0 0 1rem rgba(black, 0.1), 0 rem 1rem 1rem -1rem rgba(black, 0.2);

      font-family: Red hat Display, sans-serif;
      font-weight: 400;
      letter-spacing: 0.025em;

      &::placeholder {
        color: $text-3;
      }
    }
  }
}

@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }

  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}
</style>
